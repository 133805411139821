import React, {useEffect, useRef, useState} from 'react';
import "../../App.css"
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import axios from "axios";
import TextFieldComp from "../TextFieldComponent";
import SelectComp from "../SelectComponent";
import CheckboxComp from "../CheckboxComp";
import { IconButton } from '@mui/material'; // Import IconButton from MUI
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import baseLink from "../data/api-base-link";
import myTheme from "../themes/mui-theme";
import CustomTextField from "../CustomTextField";

export const setLocalApiKey = (apiKey) => {
    localStorage.setItem('apiKey', apiKey);
};

export const getLocalApiKey = () => {
    return localStorage.getItem('apiKey');
};



function ProductSettings({token, setGameData, setError, setSuccess, menuItems, apiKeyData}) {
    // rendering logic for middle section where you select a game based off the product
    const historyListRef = useRef(null);
    const [activeRow, setActiveRow] = useState(null);
    const [product, setProduct] = useState("")
    const [pID, setId] = useState("")
    const [amountDict, setAmountDict] = useState({})
    const [keyDict, setKeyDict] = useState({})
    const [keyCount, setKeyCount] = useState({})
    const [isStockedItem, setStockedItem] = useState(false)
    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const [newProductID, setNewProductID] = useState(null)
    const [newProductName, setNewProductName] = useState("")
    const [newProductLengths, setNewProductLengths] = useState({})
    const [newLength, setNewLength] = useState(0)
    const [newPrice, setNewPrice] = useState(0)
    const [pName, setName] = useState("")
    const [status, setStatus] = useState("")
    const [sellerVisibility, setSellerVisibility] = useState(false)
    const [apiKey, setApiKey] = useState(getLocalApiKey())
    const [isApiPopupOpen, setIsApiPopupOpen] = useState(false)
    const [apiLinkDict, setApiLinkDict] = useState(false)
    const [isApiKeyPopupOpen, setIsApiKeyPopupOpen] = useState(false)
    const [apiKeySearch, setApiKeySeach] = useState("")
    const [tempApiKey, setTempApiKey] = useState("")
    const [selectedEmoji, setSelectedEmoji] = useState(0);
    const emojis = ['🟢','🟡','🟠','🔵','🔴'];


    useEffect(() => {
        const handleScroll = () => {
            const list = historyListRef.current;
            if (list) {
                const rows = list.querySelectorAll('.row');
                rows.forEach(row => {
                    const rect = row.getBoundingClientRect();
                    const listRect = list.getBoundingClientRect();

                    // Calculate visible height within the list viewport
                    const visibleHeight = Math.min(rect.bottom, listRect.bottom) - Math.max(rect.top, listRect.top);

                    // Ensure the visible height is within valid bounds
                    const clampedVisibleHeight = Math.max(0, Math.min(rect.height, visibleHeight));

                    // Calculate visibility percentage
                    const visibilityPercentage = clampedVisibleHeight / rect.height;

                    // Apply styles based on visibility
                    if (visibilityPercentage > 0 && visibilityPercentage < 1) {
                        row.style.opacity = visibilityPercentage.toString();
                        row.style.transform = `scale(${visibilityPercentage})`;
                        row.style.visibility = 'visible';
                    } else if (visibilityPercentage <= 0) {
                        row.style.opacity = '0';
                        row.style.transform = 'scale(0)';
                        row.style.visibility = 'hidden'; // Hide the row completely
                    } else {
                        row.style.opacity = '1';
                        row.style.transform = 'scale(1)';
                        row.style.visibility = 'visible';
                    }
                });
            }
        };

        const list = historyListRef.current;
        if (list) {
            list.addEventListener('scroll', handleScroll);
            // Call handleScroll initially to set the correct state on load
            handleScroll();
        }

        return () => {
            if (list) {
                list.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);
    // add useEffect for retrieving userdata here, should only run once






    const handleRowClick = (key) => {
        setActiveRow(key);
    };

    const handleApiKeySearch = (event) => {
        setApiKeySeach(event.target.value)
    }


    const handleAddProduct = () => {
        if (isPopupOpen && menuItems && product && menuItems[product] && menuItems[product].visibility) {
            setSellerVisibility(menuItems[product].visibility)
        }
        else {
            setSellerVisibility(false)
        }
        setIsPopupOpen(!isPopupOpen);
    };

    const handleRemoveApiKey = () => {
        setApiKey("")
        setLocalApiKey("")
        setApiLinkDict(false)
    }

    const handleCreateKey = async (type) => {
        try {
            const response = await axios.get(`https://${baseLink}/api/keys/${type}/${token}`);

            if (response.status === 200) {
                if (response.data) {
                    setApiLinkDict(response.data) // change this to add new key to api link dict
                }
            }
        } catch(error) {
            if (error.response) {
                setError(error.response.data)
            }
            else {
                setError("Application Error")
            }
        }
    }
    const handleManageApiKeys = () => {
        setIsApiKeyPopupOpen(!isApiKeyPopupOpen)
    }

    const handleToggleApiPopup = () => {
        setIsApiPopupOpen(!isApiPopupOpen)
        if (apiKey && !(apiKey === "")) {
            handleAPILinks()
        }
        setApiLinkDict(false)
    }

    const handleAPILinks = async () => {
        setApiKey(tempApiKey)
        setLocalApiKey(apiKey); // set api key to local storage
        try {
            const response = await axios.get(`https://${baseLink}/api/links/${product}/${apiKey}/${token}`);

            if (response.status === 200) {
                if (response.data) {
                    setApiLinkDict(response.data)
                }
            }
        } catch (error) {
            if (error.response) {
                setError(error.response.data)
            } else {
                setError("Application Error")
            }
        }
    }

    const updateKeyCount = (newKeyDict) => {
        const newKeyCount = {};
        for (const key in newKeyDict) {
            const nonEmptyLinesCount = newKeyDict[key].split("\n").filter(line => line.trim() !== "").length;
            newKeyCount[key] = nonEmptyLinesCount;
        }
        setKeyCount(newKeyCount);
    };

    const handleSetKeyDict = (length, value) => {
        try {
            // Create a new amountDict with the updated value
            const newKeyDict = {
                ...keyDict,
                [length]: value.target.value
            };

            // Update the state with the new amountDict
            setKeyDict(newKeyDict);
            updateKeyCount(newKeyDict);

        }
        catch(error) {
            setError(error)
        }
    }



    const handleRestock = async () => {
        const stockDict= {"stock":keyDict}
        try {
            const response = await axios.post(`https://${baseLink}/settings/admin/products/edit/${product}/${token}`, stockDict);

            if (response.status === 200) {
                if (response.data) {
                    setSuccess(response.data)
                }
            }
        } catch(error) {
            if (error.response) {
                setError(error.response.data)
            }
            else {
                setError("Application Error")
            }
        }
    }

    const createNewProduct = async () => {
        const productDict = {name: newProductName, stocked: isStockedItem, id:newProductID, lengths: newProductLengths, visibility: sellerVisibility}
        try {
            const response = await axios.post(`https://${baseLink}/settings/admin/products/new/${token}`, productDict);

            if (response.status === 200) {
                if (response.data) {
                    setSuccess(response.data)
                    setIsPopupOpen(false)
                }
            }
        } catch(error) {
            if (error.response) {
                setError(error.response.data)
            }
            else {
                setError("Application Error")
            }
        }
    }

    const handleDeleteProduct = async () => {
        try {
            const response = await axios.get(`https://${baseLink}/settings/admin/products/delete/` + product + "/" + token);
            if (response.status === 200) {
                setSuccess(response.data)
            }
        } catch(error) {
            if (error.response) {
                setError(error.response.data)
            }
            else {
                setError("Application Error")
            }
        }
    }

    const setProductPricing = async () => {
        const settingsDict = {"prices":amountDict}
        try {
            const response = await axios.post(`https://${baseLink}/settings/admin/products/edit/` + product + "/" + token, settingsDict);
            if (response.status === 200) {
                setSuccess(response.data)
            }
        } catch(error) {
            if (error.response) {
                setError(error.response.data)
            }
            else {
                setError("Application Error")
            }
        }


    }

    const setProductInfo = async () => {
        let settingsDict = {}

        if (!(pID === "")) {
            settingsDict["id"] = pID
        }
        if (!(pName === "")) {
            settingsDict['name'] = pName
        }

        if (!(status === "")) {
            settingsDict['status'] = status
        }

        if (!(sellerVisibility === null)) {
            settingsDict['visibility'] = sellerVisibility
        }

        const tempDict = {0: "green", 1: "yellow", 2:"orange", 3:"blue", 4:"red"}
        settingsDict['statuscolor'] = tempDict[selectedEmoji]

        console.log(settingsDict)
        try {
            const response = await axios.post(`https://${baseLink}/settings/admin/products/edit/` + product + "/" + token, settingsDict);

            if (response.status === 200) {
                setSuccess(response.data)
            }
        } catch(error){
            if (error.response) {
                setError(error.response.data)
            }
            else {
                setError("Application Error")
            }
        }
    }

    const handleDeleteApiKey = () => {
        if (!activeRow) {
            setError("Please select a key to delete")
            return
        }


    }
    const clearNewProductDict = (event) => {
        setNewProductLengths({})
    }
    const handleNewLengthChange = (event) => {
        let val = event.target.value;

        // Remove leading zeros
        val = val.replace(/^0+/, '');

        // If the resulting value is empty, set it to "0"
        if (val === '') {
            val = '0';
        }

        // Convert to number
        const numberVal = parseInt(val, 10);

        // Check if the parsed value is NaN or negative
        if (isNaN(numberVal) || numberVal < 0) {
            setNewLength(0);
        } else {
            setNewLength(numberVal);
        }
    }

    const handleNewPriceChange = (event) => {
        let val = event.target.value.replace(/^0+(?!\.|$)/, '');
        val = val.replace(/^0+(?!\.|$)/, '');
        let FloatValue = parseFloat(val);

        // Check if the parsed value is NaN or negative
        if (isNaN(FloatValue) || FloatValue < 0) {
            // If NaN or negative, set the value to 0
            FloatValue = 0.0;
        }
        setNewPrice(FloatValue)

    }


    const handleAddNewPriceLengthPair = () => {

        try {// Create a new amountDict with the updated value
            const newProductPriceDict = {
                ...newProductLengths,
                [newLength]: newPrice
            };

            // Update the state with the new amountDict
            setNewProductLengths(newProductPriceDict);
            setSuccess("Added length")

        }
        catch(error) {
            setError(error)
        }
    };

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setSuccess('Copied to clipboard');
                // You can add a success message or other feedback here if needed
            })
            .catch((err) => {
                setError('Failed to copy to clipboard');
            });
    };


    const handleApiKey = (event) => {
        setApiKey(event.target.value)
    }
    const handleNewProductName = (event) => {
        setNewProductName(event.target.value)
    }

    const handleNewProductID = (event) => {
        setNewProductID(event.target.value)
    }

    const toggleResellerVisibility = (event) => {
        setSellerVisibility(event.target.checked)
    }
    const toggleStockedItem = (event) => {
        setStockedItem(event.target.checked)
    }

    const handleStatusChange = (event) => {
        setStatus(event.target.value)
    }

    const handleIDChange = (event) => {
        setId(event.target.value)
    }

    const handleNameChange = (event) => {
        setName(event.target.value)
    }

    const setPriceDict = (length, value) => {
        let formattedValue = value.replace(/^0+/, '');
        if (formattedValue === '') {
            formattedValue = "0";
        }
        if (formattedValue.startsWith("0") && formattedValue.length > 1) {
            formattedValue = formattedValue.substring(1, formattedValue.length)
        } else {
            // Convert the value to a float
            formattedValue = parseFloat(formattedValue);
            if (isNaN(formattedValue)) {
                // Handle cases where parseFloat fails (e.g., input is non-numeric)
                formattedValue = '';
            }
        }

        // Create a new amountDict with the updated value
        const newAmountDict = {
            ...amountDict,
            [length]: formattedValue
        };

        // Update the state with the new amountDict
        setAmountDict(newAmountDict);

    };


    const setProductEvent = (event) => {
        setProduct(event.target.value)
        // set status
        setStatus(menuItems[event.target.value].status)
        setSelectedEmoji(parseInt(menuItems[event.target.value].statuscolor))
        // set visibility of product for resellers
        setSellerVisibility(menuItems[event.target.value].visibility)
        if (menuItems && menuItems[event.target.value] && menuItems[event.target.value].stock) {
            setKeyDict(menuItems[event.target.value].stock)
            updateKeyCount(menuItems[event.target.value].stock)
        }


    }



    return (
        <div className="PageDefaults">
            <div className={"BoxItemFlexBox"}>
                <div className={"ProductManage"}>
                    <h2>Product Pricing</h2>
                    <div className={"BoxItem"}>
                        <div className={"BoxScrollContainer"}>
                            <div className={"ProductSettingsFlexbox"}>
                                <div className={"PricingSettings"}>
                                    <p>Select Product</p>
                                    <div>

                                        <SelectComp onChangeFunc={setProductEvent} label={"Select product"}
                                                    menuItems={menuItems}/>
                                        {product && menuItems[product] && menuItems[product].lengths &&
                                            Object.keys(menuItems[product].lengths).map((length) => {
                                                const price = menuItems[product].lengths[length];
                                                return (
                                                    <>
                                                        <p><em>{length.charAt(0).toUpperCase() + length.slice(1)}</em> {" (Current: $ " + price + ")"}</p>
                                                        <ThemeProvider theme={myTheme}>
                                                            <Box
                                                                component="form"
                                                                sx={{
                                                                    '& > :not(style)': {
                                                                        m: 1,
                                                                        width: '25ch',
                                                                        color: "white",
                                                                        backgroundColor: myTheme.palette.background.main,
                                                                    },
                                                                    '& input': {
                                                                        color: 'white', // Set text color to white
                                                                    },
                                                                    '& .MuiOutlinedInput-root': {
                                                                        '& fieldset': {
                                                                            borderColor: myTheme.palette.primary.main, // Set outline color to #b59c02
                                                                        },
                                                                        '&:hover fieldset': {
                                                                            borderColor: myTheme.palette.primary.main, // Set outline color on hover to #b59c02
                                                                        },
                                                                        '&.Mui-focused fieldset': {
                                                                            borderColor: myTheme.palette.primary.main, // Set outline color when focused to #b59c02
                                                                        },
                                                                    },
                                                                }}
                                                                noValidate
                                                                autoComplete="off"
                                                            >
                                                                <TextField
                                                                    type="number" // Change type to "text"
                                                                    onChange={(event) => setPriceDict(length, event.target.value.replace(/^0+/, ''))} // Remove leading zeros
                                                                    color="primary"
                                                                    label={"Set Price"}
                                                                    variant="outlined"
                                                                    focused
                                                                    value={amountDict[length] && amountDict[length].toString() || '0'} // Display the value as is from the state
                                                                    inputProps={{min: "0", maxLength: 5}}
                                                                />
                                                            </Box>
                                                        </ThemeProvider>
                                                    </>

                                                )
                                                    ;
                                            })}
                                    </div>
                                    {product === "" ? (
                                        <button onClick={handleAddProduct}>
                                            Add New Product
                                        </button>
                                    ) : (
                                        <div className={"vSort"}>
                                            <div>
                                                <button onClick={setProductPricing}>
                                                    Save Pricing
                                                </button>
                                            </div>
                                            <div>
                                                <button onClick={handleDeleteProduct}>
                                                    Delete Product
                                                </button>
                                            </div>
                                            <div>
                                                <button onClick={handleAddProduct}>
                                                    Add New Product
                                                </button>
                                            </div>

                                        </div>
                                    )}


                                </div>
                            </div>
                        </div>
                        {isPopupOpen && (
                            <div className="popup">
                                <div className="popup-content-large">
                                    <span className="close-btn" onClick={handleAddProduct}>&times;</span>
                                    <div className={"hSort"}>
                                        <div className="vSort">
                                            <p>Product Name</p>
                                            <TextFieldComp isMultiline={false} type={"text"} label={"Enter Name"}
                                                           placeholder={"Case Sensitive"}
                                                           onChangeFunc={handleNewProductName}/>
                                            <p>Product ID</p>
                                            <TextFieldComp isMultiline={false} type={"text"} label={"Enter ID"}
                                                           placeholder={"Integers Only"}
                                                           onChangeFunc={handleNewProductID}/>
                                            <CheckboxComp onChangeFunc={toggleStockedItem} label={"Stocked Item"}/>
                                            <CheckboxComp onChangeFunc={toggleResellerVisibility} label={"Public For Resellers"}/>

                                        </div>
                                        <div className={"vSort"}>
                                            <div>
                                                <p>Enter Pricing Details</p>
                                                <TextFieldComp type={"number"} label={"Amount of Days"}
                                                               placeholder={"Integers Only"}
                                                               onChangeFunc={handleNewLengthChange}
                                                               value={newLength.toString()}/>
                                                <TextFieldComp type={"number"} label={"Price"} placeholder={""}
                                                               onChangeFunc={handleNewPriceChange}
                                                               value={newPrice.toString()}/>
                                                <button onClick={handleAddNewPriceLengthPair}>Add New Length</button>
                                                <button style={{marginLeft: "1ch"}}
                                                        onClick={clearNewProductDict}>Clear
                                                </button>
                                                <ul className={"iconlist"}></ul>
                                                {newProductLengths && Object.keys(newProductLengths).map((key, value) => (
                                                    <div className={"iconlistitem"}>
                                                        <p>
                                                            <em>{key} Day{!(key === "1") && ("s")}:</em> $ {newProductLengths[key]}
                                                        </p>
                                                    </div>
                                                ))}
                                            </div>


                                        </div>


                                    </div>

                                </div>
                                <button onClick={createNewProduct}>
                                    Save Product
                                </button>
                            </div>
                        )}
                    </div>
                </div>

                {!(product === "") && (
                    <div className={"ProductManage"}>
                        <h2>Product Details</h2>
                        <div className={"BoxItem"}>
                            <div className={"ProductSettingsFlexbox"}>
                                <div className={"OtherSettings"}>
                                    <p>Change Product Name</p>
                                    <TextFieldComp label={"Enter Product Name"} placeholder={"Case Sensitive"}
                                                   type={"text"}
                                                   onChangeFunc={handleNameChange}/>
                                    <p>Change Product ID</p>
                                    {menuItems && menuItems[product] && menuItems[product].id ? (
                                        <p><em>ID:</em> {menuItems[product].id}</p>
                                    ) : (
                                        <p><em>Status not available </em></p>
                                    )}
                                    <TextFieldComp label={"Enter Product ID"} placeholder={"ID"} value={pID}
                                                   type={"number"}
                                                   onChangeFunc={handleIDChange}/>
                                    <p>Change Product Status</p>
                                    {menuItems && menuItems[product] && menuItems[product].status ? (
                                        <p><em>Status:</em> {menuItems[product].status}</p>
                                    ) : (
                                        <p><em>Status not available </em></p>
                                    )}

                                    <TextFieldComp label={"Enter Status"} value={status} placeholder={"Status"} type={"text"}
                                                   onChangeFunc={handleStatusChange}/>

                                    <div>
                                        <div className={"emoji-container"}>
                                            {emojis.map((emoji, index) => (
                                                <div
                                                    key={index}
                                                    onClick={() => setSelectedEmoji(index)}
                                                    className={`emoji ${selectedEmoji === index ? 'selected' : ''}`}
                                                >
                                                    {emoji}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <CheckboxComp onChangeFunc={toggleResellerVisibility} label={"Public For Resellers"} checked={sellerVisibility}/>
                                <div>
                                    <button onClick={setProductInfo}>
                                        Save Info
                                    </button>

                                </div>
                                <div>
                                    {/*
                                    <button onClick={handleManageApiKeys}>
                                        Manage API Keys
                                    </button>
                                    */}
                                    {isApiKeyPopupOpen && (

                                        <div className="popup">
                                            <div className="popup-content-large">
                                                        <span className="close-btn"
                                                              onClick={handleManageApiKeys}>&times;</span>
                                                <div className={"hSort"}>
                                                    <div className={"vSort"}>
                                                        <p>Select API Keys</p>
                                                        <TextFieldComp type={"text"} onChangeFunc={handleApiKeySearch}
                                                                       placeholder={"Key or User"} label={"Search Key or User"}
                                                                       value={apiKeySearch} isMultiline={false}/>
                                                        <div className={"HistoryResults"}>
                                                            <ul className={"HistoryList"} ref={historyListRef}>

                                                                {Object.entries(apiKeyData).map(([key, val]) => (
                                                                    <>
                                                                        {!apiKeySearch ? (
                                                                            <li key={key}
                                                                                className={`row ${activeRow === key ? 'active' : ''}`}
                                                                                id={key}
                                                                                onClick={() => handleRowClick(key)}>
                                                                                <div id="title">
                                                                                    <div className={"rowText"}>
                                                                                        <em>{val}:</em> <br/>{key}<br/>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        ) : (
                                                                            <>
                                                                                {apiKeySearch && apiKeySearch.toLowerCase().startsWith(key.toLowerCase()) || (key.toLowerCase().includes(apiKeySearch.toLowerCase())) || (key) && (
                                                                                    <li key={key}
                                                                                        className={`row ${activeRow === key ? 'active' : ''}`}
                                                                                        id={key}
                                                                                        onClick={() => handleRowClick(key)}>
                                                                                        <div id="title">
                                                                                            <div className={"rowText"}>
                                                                                                <p>
                                                                                                    <em>{val}:</em> {key}<br/>
                                                                                                    {/* Display other properties similarly */}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className={"vSort"}>
                                                        <p>Add or Delete Keys</p>
                                                        <div>
                                                            <button onClick={handleCreateKey}>Create Admin Key
                                                            </button>
                                                        </div>

                                                        <div>
                                                            <button onClick={handleDeleteApiKey}>Delete Key</button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <button onClick={handleToggleApiPopup}>
                                        Get API Links
                                    </button>
                                    {isApiPopupOpen && (

                                        <div className="popup">
                                            <div className="popup-content-large">
                                                        <span className="close-btn"
                                                              onClick={handleToggleApiPopup}>&times;</span>
                                                <div className={"hSort"}>
                                                    {!apiLinkDict ? (
                                                        <div className="vSort">
                                                            <p>Enter API Key</p>
                                                            <TextFieldComp isMultiline={false} type={"text"}
                                                                           label={"Api Key"}
                                                                           placeholder={""}
                                                                           onChangeFunc={handleApiKey}/>
                                                            <div>
                                                                <button onClick={handleAPILinks}>
                                                                    Submit
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="vSort">
                                                            <h2>API Links for <em>{product}</em></h2>
                                                            {apiLinkDict && Object.entries(apiLinkDict).map(([key, value]) => (
                                                                <div key={key} style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center'
                                                                }}>
                                                                    <p style={{marginRight: '8px'}}>
                                                                        <em>{key}:</em> {value}
                                                                    </p>
                                                                    <IconButton className="copyButton"
                                                                                onClick={() => handleCopy(value)}
                                                                                size={"small"}>
                                                                        <ContentCopyIcon sx={{
                                                                            fontSize: 20,
                                                                            color: "white",
                                                                            backgroundColor: "transparent"
                                                                        }}/>
                                                                    </IconButton>
                                                                </div>
                                                            ))}
                                                            <div>
                                                                <button onClick={handleRemoveApiKey}>
                                                                    Change API Key
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )}


                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                )}
                {!(product === "") && menuItems[product] && menuItems[product].stocked && (
                    <div className="ProductManage">
                        <h2>Stock Items</h2>
                        <div className="BoxItem">
                            <div>
                                {menuItems[product].stocked ? (
                                    <>
                                        {product && menuItems[product] && menuItems[product].lengths &&
                                            Object.keys(menuItems[product].lengths).map((length) => {
                                                return (
                                                    <>
                                                        <p>{length.charAt(0).toUpperCase() + length.slice(1)}<br/>
                                                        <em>Count: </em>{keyCount[length]}</p>
                                                        <ThemeProvider theme={myTheme}>
                                                            <Box
                                                                component="form"
                                                                sx={{
                                                                    '& > :not(style)': {
                                                                        m: 1,
                                                                        width: '25ch',
                                                                        color: "white",
                                                                        backgroundColor: myTheme.palette.background.main,
                                                                    },
                                                                    '& input': {
                                                                        color: 'white', // Set text color to white
                                                                    },
                                                                    '& .MuiInputBase-inputMultiline': {
                                                                        color: 'white', // Set text color to white for multiline input
                                                                    },
                                                                    '& .MuiOutlinedInput-root': {
                                                                        '& fieldset': {
                                                                            borderColor: myTheme.palette.primary.main, // Set outline color to #b59c02
                                                                        },
                                                                        '&:hover fieldset': {
                                                                            borderColor: myTheme.palette.primary.main, // Set outline color on hover to #b59c02
                                                                        },
                                                                        '&.Mui-focused fieldset': {
                                                                            borderColor: myTheme.palette.primary.main, // Set outline color when focused to #b59c02
                                                                        },
                                                                    }, maxHeight: '200px',
                                                                    overflow: 'auto'

                                                                }}
                                                                noValidate
                                                                autoComplete="off">
                                                                <TextField variant={"outlined"} color={"primary"} value={keyDict[length]} multiline label={"Keys"} placeholder={"Seperate with Newline"} onChange={(text) => handleSetKeyDict(length, text)} focused />
                                                            </Box>
                                                        </ThemeProvider>

                                                    </>
                                                );
                                            })}
                                        <div>
                                            <button onClick={handleRestock}>
                                                Save Stock
                                            </button>
                                        </div>
                                    </>

                                ) : (
                                    <p>Nothing to stock.</p>
                                )}
                            </div>

                        </div>

                    </div>
                )}
            </div>
        </div>
    );
}

export default ProductSettings;