import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import React from "react";
import myTheme from "./themes/mui-theme";


const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
    '&.MuiCheckbox-root': {
        color: theme.palette.secondary.main,
    },
    '&.Mui-checked': {
        color: theme.palette.primary.main,
    },
}));

function CheckboxComp({ onChangeFunc, inputProps, label, checked }) {
    return (
        <ThemeProvider theme={myTheme}>
            <FormGroup>
                <FormControlLabel
                    control={
                        <CustomCheckbox
                            inputProps={inputProps}
                            onChange={(event) => onChangeFunc(event)}
                            checked={checked}
                        />
                    }
                    label={label}
                />
            </FormGroup>
        </ThemeProvider>
    );
}

export default CheckboxComp;
