import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import axios from "axios";
import myTheme from "../themes/mui-theme";
import baseLink from "../data/api-base-link";


function Login({ setAPIKeyData, setToken, setError, setBalance, setSuccess, setDiscount, setIsAdmin, setProductData, setUserData}) {
    const [usernameValue, setUsernameValue] = useState("");
    const [passwordValue, setPasswordValue] = useState("");


    const loginWithToken = async (token) => {
        // Your login logic here
        setSuccess("Logging in...")
        try {
            const response = await axios.get(`https://${baseLink}/user/relogin/${token}`);
            if (response.data) {
                setDiscount(parseFloat(response.data.discount));
                setBalance(response.data.balance)
                setToken(token);
                setIsAdmin(response.data.secure)
                setProductData(response.data.productdata)
                setUserData(response.data.userdata)
                setAPIKeyData(response.data.apikeys)
                setSuccess("Login Successful");

            }
        }
        catch(error) {
            if (error.response) {
                if (error.response.status === 401) {
                    setError("Token expired, please login again.");
                    localStorage.removeItem("loginToken")
                    setToken(null);

                } else if (error.response.status === 500) {
                    setError("Server error. Please try again later.");
                }
                else if (error.response.status === 429) {
                    setError("Too many requests, please wait 1 minute.");
                }
                else if (error.response.status === 400) {
                    setError(error.response.data);
                }
                else {
                    setError("An unexpected error occurred")
                }
            } else {
                setError("An unexpected error occurred.");
            }
        }

    };

    useEffect(() => {
        const token = localStorage.getItem('loginToken');
        if (token) {
            loginWithToken(token);
        }
    }, []); // Empty dependency array ensures this runs only once


    const handleUsernameChange = (event) => {
        setUsernameValue(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPasswordValue(event.target.value);
    };



    const handleLogin = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        try {
            if (usernameValue === "" || passwordValue === "") {
                setError("Please enter a username and password.");
                return;
            }
            setSuccess("Logging in...")
            const response = await axios.get(`https://${baseLink}/user/login/${usernameValue}/${passwordValue}`);
            if (response.data && response.data.token && response.data.discount) {
                setDiscount(parseFloat(response.data.discount));
                setBalance(response.data.balance)
                localStorage.setItem('loginToken', response.data.token);
                setToken(response.data.token);
                setIsAdmin(response.data.secure)
                setProductData(response.data.productdata)
                setUserData(response.data.userdata)
                setSuccess("Login Successful");
                setAPIKeyData(response.data.apikeys)
            }
        } catch (error) {
            if (error.response) {
                setError(error.response.data)
            }
            // Handle errors
        }
    };


    return (
        <div className={"PageDefaults"}>

            <div className={"login"}>
                <form autoComplete="on" onSubmit={handleLogin}>
                    <div className={"BoxItem"} style={{alignItems: "center"}}>
                        <div className={"loginitem"}>
                            <ThemeProvider theme={myTheme}>
                                <Box
                                    component="div"
                                    sx={{
                                        '& > :not(style)': {
                                            m: 1,
                                            width: '400px',
                                            color: "white",
                                            backgroundColor: myTheme.palette.background.main,
                                        },
                                        '& input': {
                                            color: 'white', // Set text color to white
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: myTheme.palette.primary.main, // Set outline color to #b59c02
                                            },
                                            '&:hover fieldset': {
                                                borderColor: myTheme.palette.primary.main, // Set outline color on hover to #b59c02
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: myTheme.palette.primary.main, // Set outline color when focused to #b59c02
                                            },
                                        },
                                    }}
                                >
                                    <TextField
                                        placeholder="Required"
                                        name="username"
                                        value={usernameValue}
                                        onChange={handleUsernameChange}
                                        color="primary"
                                        label="Username"
                                        variant="outlined"
                                        focused
                                    />
                                </Box>
                            </ThemeProvider>
                        </div>
                        <div className={"loginitem"}>
                            <ThemeProvider theme={myTheme}>
                                <Box
                                    component="div"
                                    sx={{
                                        '& > :not(style)': {
                                            m: 1,
                                            width: '400px',
                                            color: "white",
                                            backgroundColor: myTheme.palette.background.main,
                                        },
                                        '& input': {
                                            color: 'white', // Set text color to white
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: myTheme.palette.primary.main, // Set outline color to #b59c02
                                            },
                                            '&:hover fieldset': {
                                                borderColor: myTheme.palette.primary.main, // Set outline color on hover to #b59c02
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: myTheme.palette.primary.main, // Set outline color when focused to #b59c02
                                            },
                                        },
                                    }}
                                >
                                    <TextField
                                        type="password"
                                        placeholder="Required"
                                        name="password"
                                        value={passwordValue}
                                        onChange={handlePasswordChange}
                                        color="primary"
                                        label="Password"
                                        variant="outlined"
                                        focused
                                    />
                                </Box>
                            </ThemeProvider>

                        </div>
                        <div className={"loginitem"}>
                            <button type="submit">
                                Login
                            </button>

                        </div>


                    </div>
                </form>


            </div>


        </div>
    );
}

export default Login;
