import './App.css';
import React, {useState, useEffect } from 'react';
import Sidebar from "./components/Sidebar"
import BuyKeys from "./components/pages/buykeys"
import Login from "./components/pages/login"
import Logout from './components/pages/logout'
import Settings from "./components/pages/settings"
import ErrorMessage from "./components/errorBox"
import SuccessMessage from "./components/msgBox";
import ProductSettings from "./components/pages/productsettings";
import UserSettings from "./components/pages/usersettings";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from "react-router-dom";
import axios from 'axios';




function App() {
    const [gameData, setGameData] = useState(null);
    const [userData, setUserData] = useState(null)
    const [token, setToken] = useState(null);
    const [discount, setDiscount] = useState(0);
    const [error, setError] = useState("")
    const [successMsg, setSuccessMsg] = useState("")
    const [isAdmin, setIsAdmin] = useState(null)
    const [balance, setBalance] = useState(0)
    const [isOpen, setIsOpen] = useState(false);
    const [apiKeyData, setAPIKeyData]=  useState(null);
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    // error message
    useEffect(() => {
        if (error) {
            const timer = setTimeout(() => {
                setError('');
            }, 5000);  // Display for 5 seconds
            return () => clearTimeout(timer);
        }
    }, [error]);

    useEffect(() => {
        if (successMsg) {
            const timer = setTimeout(() => {
                setSuccessMsg('');
            }, 5000);  // Display for 5 seconds
            return () => clearTimeout(timer);
        }
    }, [successMsg]);




    if(!token) {
        return (<div>
                {error && <ErrorMessage message={error} />}
                {successMsg && <SuccessMessage message={successMsg} />}
                <Login setAPIKeyData={setAPIKeyData} setUserData={setUserData} setProductData={setGameData} setIsAdmin={setIsAdmin} setDiscount={setDiscount} setToken={setToken} setBalance={setBalance} setError={setError} setSuccess={setSuccessMsg} />
            </div>
        )
    }




    return (
        <Router>
            <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            <div className="App">
                <div>
                    <button className="hamburger" onClick={toggleSidebar}>
                        ☰
                    </button>
                </div>
                <Sidebar isAdmin={isAdmin} isOpen={isOpen}/>
                <Routes>
                    <Route path="/buykeys"
                           element={<BuyKeys setBalance={setBalance} setDiscount={setDiscount} discount={discount} balance={balance} gamedata={gameData} token={token}
                                             setError={setError} setSuccess={setSuccessMsg}/>}></Route>
                    <Route path="/settings"
                           element={<Settings isAdmin={isAdmin} token={token} setError={setError}
                                              setSuccess={setSuccessMsg}/>}></Route>
                    <Route path="/logout" element={<Logout token={token} setToken={setToken} setError={setError}
                                                           setSuccess={setSuccessMsg}/>}></Route>
                    {isAdmin && (
                        <>
                            <Route path="/productsettings"
                                   element={<ProductSettings apiKeyData={apiKeyData} setGameData={setGameData} menuItems={gameData} token={token} setError={setError}
                                                             setSuccess={setSuccessMsg}/>}></Route>
                            <Route path="/usersettings"
                                   element={<UserSettings setUserData={setUserData} token={token} setError={setError} userInfo={userData}
                                                          setSuccess={setSuccessMsg}/>}></Route>
                        </>
                    )}
                </Routes>
                {error && <ErrorMessage message={error}/>}
                {successMsg && <SuccessMessage message={successMsg}/>}
            </div>
        </Router>
    );
}

export default App;
