import React, { useState } from "react";
import "../App.css";
import { SideBarData } from './data/SidebarData';
import { Link, useLocation } from "react-router-dom";

function Sidebar({ isAdmin, isOpen }) {
    const location = useLocation();

    // Filter the SideBarData based on isAdmin prop
    const filteredData = SideBarData.filter(item => {
        if (item.requiresAdmin) {
            return isAdmin;
        }
        return true;
    });



    return (
        <div className={`Sidebar ${isOpen ? "open" : ""}`}>


            <h2>Desync Panel</h2>
            <ul className="SidebarList">
                {filteredData.map((val, key) => (
                    <Link to={val.link} key={key} activeClassName="active" style={{textDecoration: 'none'}}>
                        <li className="row" id={location.pathname === val.link ? "active" : ""}>
                            <div id="icon">{val.icon}</div>
                            <div id="title">{val.title}</div>
                        </li>
                    </Link>
                ))}
            </ul>
        </div>
    );
}

export default Sidebar;
