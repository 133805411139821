export const saveAction = (action) => {
    const actions = JSON.parse(localStorage.getItem('actions')) || [];
    actions.push({ ...action, date: new Date().toISOString() });
    localStorage.setItem('actions', JSON.stringify(actions));
};

export const getActions = () => {
    const actions = JSON.parse(localStorage.getItem('actions')) || [];
    // Sort actions by date (newest first)
    actions.sort((a, b) => new Date(b.date) - new Date(a.date));
    return actions;
};

export const clearActions = () => {
    localStorage.removeItem('actions');
};